import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import jwt from 'jwt-decode'
//const role=localStorage.getItem('role');
let role="";
const token=localStorage.getItem("auth_token");
if(token){
role=jwt(token).role;
}
export const AppViews = () => {
  useEffect(()=>{
    // alert('hi')
  })
  return (
    <Suspense fallback={<Loading cover="content" />}>
       {role=="admin" ?  (<Switch>
               
         {/* School Route */}
         <Route exact path={`${APP_PREFIX_PATH}`} 
        component={lazy(()=>import('./dashboard/index'))}/>

       <Route path={`${APP_PREFIX_PATH}/courses/school`} 
        component={lazy(()=>import('./school/index'))}/>
        

        <Route path={`${APP_PREFIX_PATH}/courses/add/school`} 
        component={lazy(()=>import('./school/formSchool'))}/>

        <Route path={`${APP_PREFIX_PATH}/courses/edit/school/:id`} 
        component={lazy(()=>import('./school/editSchool'))}/>
         {/* School Route End*/}

         {/* Grade Route */}
        <Route path={`${APP_PREFIX_PATH}/courses/grade`} 
        component={lazy(()=>import('./grade/index'))}/>

        
        <Route path={`${APP_PREFIX_PATH}/courses/add/grade`} 
        component={lazy(()=>import('./grade/formGrade'))}/>

        
        <Route path={`${APP_PREFIX_PATH}/courses/edit/grade/:id`} 
        component={lazy(()=>import('./grade/editGrade'))}/>
        {/* Grade Route End*/}

         {/* Course Route */}
        <Route path={`${APP_PREFIX_PATH}/courses/course`} 
        component={lazy(()=>import('./course/index'))}/>

        <Route path={`${APP_PREFIX_PATH}/courses/add/course`} 
        component={lazy(()=>import('./course/formCourse'))}/>

        
        <Route path={`${APP_PREFIX_PATH}/courses/edit/course/:id`} 
        component={lazy(()=>import('./course/editCourse'))}/>

        <Route path={`${APP_PREFIX_PATH}/courses/content/course/:id`} 
        component={lazy(()=>import('./course/draftContent/index'))}/>
         {/* Course Route End*/}

        {/* contributors routes */}
        <Route path={`${APP_PREFIX_PATH}/contributors/edit/contributor/:id`} 
        component={lazy(()=>import('./contributors/contributorEdit'))}/>

        <Route path={`${APP_PREFIX_PATH}/contributors/view/contributor/:id`} 
        component={lazy(()=>import('./contributors/contributorView'))}/>

        <Route path={`${APP_PREFIX_PATH}/contributors/add/contributor`} 
        component={lazy(()=>import('./contributors/formContributor'))}/>
        <Route path={`${APP_PREFIX_PATH}/contributors`} 
        component={lazy(()=>import('./contributors/index'))}/>
        {/*  contributors routes End*/}
        {/* discussions routes start*/}
        <Route path={`${APP_PREFIX_PATH}/discussion/add/admin`} 
        component={lazy(()=>import('./discussion/addDiscussion'))}/>

        <Route path={`${APP_PREFIX_PATH}/discussions`} 
        component={lazy(()=>import('./discussion/index'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/comments/admin/:id`} 
        component={lazy(()=>import('./discussion/newcomments'))}/>

        {/* discussion routes end */}
        {/* feedback routes start*/}
        <Route path={`${APP_PREFIX_PATH}/feedback/admin/completed/:id`} 
        component={lazy(()=>import('./feedback/completed/view'))}/>
        <Route path={`${APP_PREFIX_PATH}/feedback/admin/processing/:id`} 
        component={lazy(()=>import('./feedback/processing/view'))}/>
        <Route path={`${APP_PREFIX_PATH}/feedback/admin/pending/:id`} 
        component={lazy(()=>import('./feedback/pending/view'))}/>
        <Route path={`${APP_PREFIX_PATH}/feedback`} 
        component={lazy(()=>import('./feedback/index'))}/>
       

        {/* feedback routes end */}
        {/* Users Route */}
        

        <Route path={`${APP_PREFIX_PATH}/users/add/user`} 
        component={lazy(()=>import('./user/formUser'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/resset/user/password/:id`} 
        component={lazy(()=>import('./user/resetpassword'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/edit/user/:id`} 
        component={lazy(()=>import('./user/editUser'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/assign_course/user/:id`} 
        component={lazy(()=>import('./user/assign_course/index'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/assign_students/user/:id`} 
        component={lazy(()=>import('./user/assign_student/index'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/add/userAssignStudent/user/:id`} 
        component={lazy(()=>import('./user/assign_student/formUserAssign'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/add/userAssignCourse/user/:id`} 
        component={lazy(()=>import('./user/assign_course/formUserAssign'))}/>

        <Route path={`${APP_PREFIX_PATH}/users/edit/userAssignCourse/user/:id/:userid`} 
        component={lazy(()=>import('./user/assign_course/editUserAssign'))}/>


        <Route path={`${APP_PREFIX_PATH}/users/add/userAssignGrade/user/:id`} 
        component={lazy(()=>import('./user/assign_grade/formUserAssign'))}/>

        <Route path={`${APP_PREFIX_PATH}/users`} 
        component={lazy(()=>import('./user/index'))}/>
        {/* Users Route End*/}
        
        
       

      </Switch>): role=="teacher" ?  (<Switch>
        {/* Teacher Discussion */}
        <Route exact path={`${APP_PREFIX_PATH}/openai`} 
        component={lazy(()=>import('./dashboard/chat'))}/>
        <Route exact path={`${APP_PREFIX_PATH}`} 
        component={lazy(()=>import('./dashboard/teacher'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/teacher`} 
        component={lazy(()=>import('./discussion/teacher/index'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/withContent/teacher/:id`} 
        component={lazy(()=>import('./discussion/teacher/discussion'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/add/teacher`} 
        component={lazy(()=>import('./discussion/teacher/addDiscussion'))}/>
        {/* Teacher Assignments */}
        <Route path={`${APP_PREFIX_PATH}/assignments/teacher`} 
        component={lazy(()=>import('./discussion/teacher/assignments/index'))}/>
        <Route path={`${APP_PREFIX_PATH}/assignment/withContent/teacher/:id/:stdid/:l_id`} 
        component={lazy(()=>import('./discussion/teacher/assignments/assignment/discussion'))}/>
        <Route path={`${APP_PREFIX_PATH}/submission/withContent/teacher/:id/:stdid/:lessonid`} 
        component={lazy(()=>import('./discussion/teacher/assignments/submission/discussion'))}/>
        
      </Switch>) : role=="student" ? (<Switch>
        {/* Student Discussion */}
        <Route path={`${APP_PREFIX_PATH}/discussion/student`} 
        component={lazy(()=>import('./discussion/student/index'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/withContent/student/:id`} 
        component={lazy(()=>import('./discussion/student/discussion'))}/>
        <Route path={`${APP_PREFIX_PATH}/discussion/add/student`} 
        component={lazy(()=>import('./discussion/student/addDiscussion'))}/>

        {/* End Student Discussion */}
                
      </Switch>) : ""}
    </Suspense>
  );
};

export default React.memo(AppViews);
