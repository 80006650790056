import axios from 'axios';
import { env } from 'configs/EnvironmentConfig';

const userInstance = axios.create({
  baseURL: `${env.API_ENDPOINT_URL}`,
});

export const signIn = (user) =>
  userInstance.request({ method: 'POST', data: user, url: '/adminsignin' });

export const signUp = (user) =>
  userInstance.request({ method: "POST", data: user, url: "/adminsignup" });
