import { BookOutlined, ClusterOutlined, CommentOutlined, ContainerOutlined,PaperClipOutlined, ScheduleFilled, SettingOutlined, UserOutlined, WeiboCircleOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import jwt from 'jwt-decode'
let role="";
const token=localStorage.getItem("auth_token");
if(token){
role=jwt(token).role;
}

//console.log(roles);
const AdmindashBoardNavTree = [
 
  {
    title: "Course Managment",   
    key: "courses",
     
    breadcrumb: false,
    submenu: [
      {
        title:"School",
        path:`${APP_PREFIX_PATH}/courses/school`,
        icon: WeiboCircleOutlined,
        key:'school'
      },
      {
        title: "Grade",
        path:`${APP_PREFIX_PATH}/courses/grade`,
        icon: ScheduleFilled,
        key:'grade'
      },
      {
        title: "Course",
        path:`${APP_PREFIX_PATH}/courses/course`,
        icon: BookOutlined,
        key: 'course',
        submenu:[
        
        ]
      },
      {
        title: "Contributors",
        path:`${APP_PREFIX_PATH}/contributors`,
        icon: ClusterOutlined,
        key: 'contributors',
      },
      {
        title: "All Discussions",
        path:`${APP_PREFIX_PATH}/discussions`,
        icon: CommentOutlined,
        key: 'alldiscussion',
      },
      {
        title: "FeedBacks",
        path:`${APP_PREFIX_PATH}/feedback`,
        icon: ContainerOutlined,
        key: 'feedback',
      }
    ],
  },
  {
    title: "User Management",
    key:"users",
    breadcrumb: false,
    submenu: [
      {
        title: "Users",
        path:`${APP_PREFIX_PATH}/users`,
        icon: UserOutlined,
        key: 'user',
      },
      
    ],
  },
  // {
  //   title: "Settings",
  //   key:"settings",
  //   breadcrumb: true,
  //   defaultOpen: false,
  //   submenu: [
  //     {
  //       title: "Settings",
       
  //       icon: SettingOutlined,
  //       key: 'setting',
        
  //       submenu:[
  //         {
  //           title: "Add Role",
  //           path: `${APP_PREFIX_PATH}/setting/add/role`,
            
  //           key: 'addrole',
  //           defaultOpen: false,
  //         },
  //         {
  //           title: "Add School",
  //           path: `${APP_PREFIX_PATH}/setting/add/school`,
            
  //           key: 'addschool',
  //           defaultOpen: false,
  //         },
  //         {
  //           title: "Add Grade",
  //           path: `${APP_PREFIX_PATH}/setting/add/grade`,
            
  //           key: 'addgrade',
  //           defaultOpen: false,
  //         },
  //       ]
  //     }      
      
  //   ],
  // },
  
];
const TeacherdashBoardNavTree=[
  {
    title: "Teacher Module",       
    
    key:"tdiscussion",
    breadcrumb: false,
    submenu: [
      {
        title:"Discussions",
        path:`${APP_PREFIX_PATH}/discussion/teacher`,
        icon: CommentOutlined,
        key:'tdiscussion'
      },
      {
        title:"Assignments",
        path:`${APP_PREFIX_PATH}/assignments/teacher`,
        icon: BookOutlined,
        key:'assignments'
      },
    ]
      
  }
];
const StudentdashBoardNavTree=[
  {
    title: "Discussion Management",  
    key:"sdiscussion",
    breadcrumb: false,
    submenu: [
      {
        title:"Student Discussion",
        path:`${APP_PREFIX_PATH}/discussion/student`,
        icon: CommentOutlined,
        key:'sdiscussion'
      },
    ]
      
  }
];
//var navigationConfig=[...AdmindashBoardNavTree];
let navigationConfig=[];

if(role=="admin"){
  navigationConfig=[...AdmindashBoardNavTree]
}else if(role=="teacher"){
  navigationConfig=[...TeacherdashBoardNavTree]
}else if(role=="student"){
  navigationConfig=[...StudentdashBoardNavTree]
}

// const navigationConfig = [...AdmindashBoardNavTree];
// const TeachernavigationConfig= [...TeacherdashBoardNavTree];
// const StudentnavigationConfig=[...StudentdashBoardNavTree]
//role=="Admin" ? AdminnavigationConfig : role=="Teacher" ? TeachernavigationConfig : role=="Student" ? StudentnavigationConfig:""
export default navigationConfig

//role=="Admin" ? AdminnavigationConfig : role=="Teacher" ? TeachernavigationConfig : role=="Student" ? StudentnavigationConfig:"";
